import { Button, Checkbox, Form, Input, message, Modal, Switch } from "antd";
import  { useEffect, useState } from "react";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import useTicketById from "../../hooks/useTicketById";
import { useMutation } from "@apollo/client";
import { MARK_TICKET_AS_COMPLETED } from "../../GraphQL/Mutations";
import Progress from "react-progress-2";
import {
  CheckCircleTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { IProduct } from "types"; 

export const getUpdatedResponseFormUiSchemaAndArryaData = (responseFormUiSchemaStr, replyDataStr) => {
  const responseFormUiSchema = JSON.parse(responseFormUiSchemaStr) 
  let parsedData = JSON.parse(replyDataStr); 
  const arrayKeys = Object.keys(parsedData).filter(key => Array.isArray(parsedData[key])); 
  const nonArrayKeys = Object.keys(parsedData).filter(key => !Array.isArray(parsedData[key]));   
  responseFormUiSchema.elements = responseFormUiSchema.elements.filter(el => {
    return nonArrayKeys.indexOf((el.scope as string).split('#/properties/')[1]) > -1;
  })
   
  const objectWithArrayData: any = [];
  arrayKeys.forEach(key => {
      objectWithArrayData.push({
          [key]: parsedData[key]  
      });
  }); 
  return { objectWithArrayData,  responseFormUiSchema}
}

export default function SummaryTicket({
  visible,
  setVisibleSummary,
  ticketId,
}) {
  const ticketById = useTicketById(ticketId);
  const [form] = Form.useForm();
  const [switchStatus, setSwichStatus] = useState<boolean>(false);
  const closeDeal = () => {
    if (switchStatus) {
      markTicketAsCompleted({
        variables: {
          ticketId: ticketId,
        },
      });
    } else {
      message.error("Please contact the customer to close the deal");
    }
  };

  const [markTicketAsCompleted] = useMutation(MARK_TICKET_AS_COMPLETED, {
    onCompleted: (data) => {
      if (data.markTicketAsCompleted.__typename === "TicketCompleted") {
        message.success("Ticket Completed");
        setVisibleSummary(false);
        Progress.hide();
      } else {
        message.error(data.markTicketAsCompleted.message);
        Progress.hide();
      }
    },
    onError: (error) => {
      message.error(error.message);
      Progress.hide();
    },
    fetchPolicy: "no-cache",
  });
  
  useEffect(() => {
    form.setFieldsValue({
      name: `${ticketById?.userDetails?.firstName} ${ticketById?.userDetails?.lastName}`,
      contact: ticketById?.userDetails?.phone,
      email: ticketById?.userDetails?.email,
    });
  }, [form, ticketById]);


  const ConfirmedProductDetails = () => {
    if(ticketById){
      const replyData = JSON.parse(ticketById.replies.find(reply => reply?.product?.id === ticketById?.confirmedProductId)?.replyData || '{}');
      console.log('replyData', replyData)
      const { objectWithArrayData,  responseFormUiSchema} =  getUpdatedResponseFormUiSchemaAndArryaData(
        ticketById.productCategory.productRequestForm.responseFormUiSchema, 
        ticketById.replies.find(reply => reply?.product?.id === ticketById?.confirmedProductId)?.replyData
      )

      return (
        <>
          <JsonForms
          schema={JSON.parse(ticketById.productCategory.productRequestForm.responseFormSchema)} 
          uischema={responseFormUiSchema}
          data={ticketById.replies && ticketById.replies.length > 0
            ? replyData
            : {}
          }
          renderers={materialRenderers}
          cells={materialCells}
          readonly
        /> 
        {objectWithArrayData.map(el => {
            return (
              <div style={{ marginBottom: 10 }} key={Object.keys(el)[0]}>
                <div style={{ fontWeight: 'bold' }}>{Object.keys(el)[0]} </div>
                {el[Object.keys(el)[0]].map(v => {
                  return (
                    <> 
                    <Checkbox
                      key={v}
                      checked
                      disabled
                      style={{
                        color: 'black', // This keeps the label text black
                      }}
                    > 
                    </Checkbox><span style={{ marginLeft: 7, marginRight: 10}}>{v}</span>
                    </>
                  );
                })}
              </div>
            );
          })}
        </>
      )
    }
    
    return 'helloooo'
  }

  return (
    <Modal
      title={`Contact - ${ticketById ? ticketById.referenceId : ""
        }`}
      centered
      visible={visible}
      onCancel={() => setVisibleSummary(false)}
      footer={null}
      width={700}
    >
      <div className="model_body">
        <p className="step_header">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          Request Details |<span>Details about the request</span>
        </p>
        {ticketById && (
          <div key={ticketById?.requestedProducts[0].id } style={{ marginTop: 30  }} className="custom-jsonforms-container">
            <JsonForms
              schema={JSON.parse(
                ticketById.productCategory.productRequestForm.requestFormSchema
              )}
              uischema={JSON.parse(
                ticketById.productCategory.productRequestForm.requestFormUiSchema
              )}
              data={JSON.parse(ticketById.productRequest.requestData)}
              renderers={materialRenderers}
              cells={materialCells}
              readonly
            />
          </div>
        )}
        <br />
        <br />
        <p className="step_header">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          {ticketById?.confirmedProductId ? 'Confirmed Product': 'Your Responses'} |<span>Details</span>
        </p>
        
          
        
        {ticketById && !ticketById?.confirmedProductId && (
          <div key={ticketById?.requestedProducts[0].id } style={{ marginTop: 30  }} className="custom-jsonforms-container">
            
            {ticketById.replies && ticketById.replies.length > 0 && (
              <>
                {ticketById.replies.map(reply => {
                  const { objectWithArrayData,  responseFormUiSchema} =  getUpdatedResponseFormUiSchemaAndArryaData(ticketById.productCategory.productRequestForm.responseFormUiSchema, reply.replyData)
                 
                  return (
                    <>
                      <p className="step_header" style={{ padding: 10 }}>
                        <>|<span>Product Name </span> : {ticketById.requestedProducts.find((rq: IProduct) => rq.id ===  reply.product.id)?.name}</>
                      </p>
                      <JsonForms
                        schema={JSON.parse(
                          ticketById.productCategory.productRequestForm.responseFormSchema
                        )}
                        uischema={responseFormUiSchema}
                        data={JSON.parse(reply.replyData)}
                        renderers={materialRenderers}
                        cells={materialCells}
                        readonly
                      />
                      {objectWithArrayData.length > 0 && objectWithArrayData.map(el => {
                        return (
                          <div style={{ marginBottom: 10 }} key={Object.keys(el)[0]}>
                            <div style={{ fontWeight: 'bold' }}>{Object.keys(el)[0]} </div>
                            {el[Object.keys(el)[0]].map(v => {
                              return (
                                <> 
                                <Checkbox
                                  key={v}
                                  checked
                                  disabled
                                  style={{
                                    color: 'black', // This keeps the label text black
                                  }}
                                > 
                                </Checkbox><span style={{ marginLeft: 7, marginRight: 10}}>{v}</span>
                                </>
                              );
                            })}
                          </div>
                        );
                      })} 
                    </>
                  );
                
                }) }
              </> 
            )} 
          </div> 
        )}
 
        {ticketById?.confirmedProductId && <>
          <p className="step_header" style={{ padding: 10 }}>
            <>|<span>Product Name </span> : {ticketById.requestedProducts.find((rq: IProduct) => rq.id === ticketById.confirmedProductId)?.name}</>
          </p>
          {ConfirmedProductDetails()} 
          </> }
        <br />
        <br />
        <p className="step_header">
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          User Details |<span>Details about the user</span>
        </p>
        {ticketById && ticketById?.status === "NO_RESPONSE" ? <></> : <>
          <Form autoComplete="off" form={form}>
            <div className="step_two_colum">
              <Form.Item className="step_input" name="name">
                <Input type="text" size="large" placeholder="User Name" />
              </Form.Item>

              <Form.Item className="step_input" name="contact">
                <Input type="text" size="large" placeholder="Contact No" />
              </Form.Item>
            </div>
            <div className="step_two_colum">
              <Form.Item className="step_input" name="email">
                <Input type="text" size="large" placeholder="Email" />
              </Form.Item>

              <Form.Item className="step_input" name="address">
                <Input type="text" size="large" placeholder="Address" />
              </Form.Item>
            </div>
          </Form>
        </>}
        {ticketById?.status === "COMPLETED" || ticketById?.status === "NO_RESPONSE" ? <></> : <div className="switch_line">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={switchStatus}
            onChange={(checked) => setSwichStatus(checked)}
          />
          <p>Contacted the customer</p>
        </div>}
        {switchStatus && (
          <div className="steps-action">
            <Button
              type="primary"
              className="primary__btn"
              onClick={() => closeDeal()}
            >
              Close Deal
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
